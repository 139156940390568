@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./styles/variables.scss";

.App {
}

.scrollMediaList {
  @apply max-h-[170px];
  foo: bar;

  img {
    object-fit: contain;
    max-height: 170px;
  }
}

.text-purple {
  color: $color-purple;
}

.text-location {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.text-light-purple {
  color: $color-light-purple;
}

.text-white {
  color: #fff;
}

.div-purple {
  background-color: $color-purple;
}

.div-green {
  background-color: $color-green;
}

.div-grey {
  background-color: $color-grey;
}

.div-light-grey {
  background-color: $color-light-grey;
}

.div-mindaro {
  background-color: $color-mindaro;
}

.div-light-purple {
  background-color: $color-light-purple;
}

.div-lightest-purple {
  background-color: $color-lightest-purple;
}

.div-light-red {
  background-color: $color-light-red;
}

.div-disabled-grey {
  background-color: $color-disabled-grey;
}

.text-green {
  color: $color-green;
}

.text-black {
  color: $color-black;
}

.text-fullblack {
  color: #000;
}

.text-grey {
  color: $color-grey;
}

.text-dark-grey {
  color: $color-dark-grey;
}

.text-light-grey {
  color: $color-light-grey;
}

.text-light-red {
  color: $color-light-red;
}

.border-green {
  border: 1px solid $color-green;
}

.border-purple {
  border: 1px solid $color-purple;
}


.border-light-red {
  border: 1px solid $color-light-red;
}

.text-new-lines {
  white-space: pre-line;
}

.text-10-24-500 {
  font-size: 10px;
  line-height: 24px;
  font-weight: 500;
}

.text-10-16-400 {
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
}

.text-10-16-600 {
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
}

.text-10-24-400 {
  font-size: 10px;
  line-height: 24px;
  font-weight: 400;
}

.text-11-16-600 {
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
}

.text-12-16-400 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.text-12-22-600 {
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
}

.text-12-14-600 {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
}

.text-12-14-500 {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.text-13-24-500 {
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
}

.text-32-40-500 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
}

.text-20-16-700 {
  font-size: 20px;
  line-height: 16px;
  font-weight: 700;
}

.text-16-16-600 {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
}

.text-16-24-700 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.text-15-16-500 {
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
}

.text-15-16-600 {
  font-size: 15px;
  line-height: 16px;
  font-weight: 600;
}

.text-15-24-500 {
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
}

.text-15-24-700 {
  font-size: 15px;
  line-height: 24px;
  font-weight: 700;
}

.text-14-16-500 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.text-14-17-400 {
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

.text-14-18-600 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.text-14-18-500 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.text-14-18-400 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.text-12-16-600 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}

.text-12-24-600 {
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
}

.text-12-24-700 {
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
}

.text-12-21-600 {
  font-size: 12px;
  line-height: 21px;
  font-weight: 600;
}

.text-12-24-400 {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
}

.text-12-24-500 {
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
}

.text-14-22-400 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.text-14-24-700 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}

.text-14-24-600 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
}


.text-14-20-500 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.text-14-24-500 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.text-14-24-400 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.text-16-24-600 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.text-16-24-500 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.text-16-24-400 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.text-20-24-400 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

.text-20-24-600 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.text-18-24-700 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.text-20-24-700 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.text-18-24-600 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.text-18-40-500 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

.text-14-16-600 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
}

.text-24-24-500 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

.text-24-40-500 {
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
}

.text-24-24-900 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 900;
}

.text-32-40-600 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}

.text-54-64-600 {
  font-size: 54px;
  line-height: 64px;
  font-weight: 600;
}

.no-max-width-mobile {
  @media (max-width: $breakpoint-desktop) {
    max-width: unset !important;
  }
}

.basicCheckbox {
  @apply flex justify-start text-left items-center pb-[16px];
  foo: bar;

  .MuiSvgIcon-root {
    fill: $color-faded-purple;
  }

  &.selected {
    .MuiSvgIcon-root {
      fill: $color-purple;
    }
  }

  .MuiFormControlLabel-label {
    @apply justify-center flex-1;
    @extend .text-12-16-400;
    @extend .text-black;
    foo: bar;
  }
}


.radioButton {
  @extend .text-14-24-500;
  @extend .flex-col;
  @apply bg-white rounded-[8px] justify-center pl-[16px] w-[152px] h-[48px];

  border: 1px solid #b8b9f7;

  &.selected {
    background-color: $color-lightest-purple;
  }

  .MuiFormControlLabel-label {
    @extend .text-14-24-500;
    foo: bar;
  }
}

.signupHalfInput {
  @apply justify-center w-[152px];
  foo: bar;
}

.dashboard-search {
  .MuiOutlinedInput-root {
    border-radius: 16px;
  }

  .MuiInputBase-input {
    padding: 12px 4px;
  }

  fieldset {
    border-color: transparent;
  }
}

.dashboardHeader {
  @apply w-full;
  foo: bar;

  .listType {
    @apply p-[8px] flex;
    border-radius: 4px;
    border: 1px solid #D6D6D6;
    background: #EDEDFF;

    &.selected {
      border: 1px solid #5658FF;
    }
  }

}

.roundLabel {
  @apply px-[32px] py-[16px] flex items-center;
  border-radius: 24px;
  background: #F1F3F9;

  color: #999999;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  &.selected {
    color: #5658FF;
  }

  &.bigFont {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }

  img {
    width: 24px;
    height: 24px;
  }

}

.badge {
  @apply flex items-center justify-center;
  border-radius: 999px;
  background-color: #D6D6D6;
  color: #6D6D6D;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

  height: 21px;
  padding: 0 8px;
}

.pagination {
  @apply pt-[16px] pb-0 px-[24px] flex justify-center gap-[20px];
  foo: bar;

  .pager {
    color: #6D6D6D;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;

    &.selected {
      text-decoration-line: underline;
      color: #5658FF;
    }
  }
}

.card-rounded {
  @media (min-width: $breakpoint-desktop) {
    border-radius: 16px;
  }
  border-radius: 8px;
}

.main-dropshadow {
  box-shadow: 0px 20px 80px rgba(17, 19, 80, 0.13);
}

.shadow-image {
  @apply lg:w-[min(552px,50%)] pt-[24px] lg:pt-0;
  foo: bar;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.w-unset {
  width: unset !important;
}

.root {
  @extend .flex-col;
  foo: bar;
}

.mobile {
  .chakra-popover__popper {
    transform: unset !important;
    min-width: unset !important;
    width: 100% !important;
  }

  &.menu-box {
    --popper-arrow-size: 0px !important;
  }

  foo: bar;
}

.burger-menu {
  @apply w-full items-start pl-[16px] py-[24px] overflow-y-hidden bg-white;
  @extend .flex-col;
  @extend .text-14-16-500;
  gap: 24px;
  transition-property: height;
  transition-duration: 0.5s;
  foo: bar;
}

.signContinueWith {
  @apply w-full;
  div:first-child {
    @apply py-[16px] px-[24px] rounded-[10px] flex gap-[32px] text-white;
    @extend .text-16-24-400;
    @extend .text-black;
    border: 1px solid #d9d9d9;
  }
}

.menu-text {
  &:hover {
    color: $color-purple;
    text-decoration-line: underline;
  }
}

.basicInput {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid $color-faded-purple;
  }

  .MuiInputLabel-root.Mui-focused {
    color: $color-faded-purple !important;
  }

  .MuiInputLabel-outlined {
    @extend .text-14-24-500;

    color: $color-faded-purple;
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $color-faded-purple !important;
    }
  }

  @apply lg:w-[500px];
  foo: bar;
}

.form-text-grey {
  .MuiFormLabel-root {
    color: $color-grey;
  }
}

.register {
  border-radius: 8px;
  background-color: $color-purple;
  color: white;

  @extend .text-14-16-600;
  @apply py-[12px] px-[32px];
  foo: bar;
}

.heroButton {
  border-radius: 8px;
  background-color: $color-purple;
  color: white;

  @extend .text-16-16-600;
  @apply py-[16px] px-[48px];
  foo: bar;
}

.menu-title {
  @extend .text-14-16-500;
  color: $color-grey;

  &.bold {
    font-weight: 600;
  }
}

.homeHero {
  min-height: 624px;

  @screen lg {
    min-height: 720px;
  }
}

.homeHeroImage {
  height: 624px;

  @screen lg {
    height: 720px;
  }

  @apply flex justify-end absolute right-0;
  foo: bar;
}

.pageRow {
  display: flex;
  flex-direction: column;
}

.text-16-24-600_18-24-600 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;

  @screen lg {
    font-size: 18px;
    line-height: 24px;
  }
}

.text-14-18-600_18-24-600 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;

  @screen lg {
    font-size: 18px;
    line-height: 24px;
  }
}

.text-14-18-400_16-24-400 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;

  @screen lg {
    font-size: 16px;
    line-height: 24px;
  }
}

.text-12-16-500_16-24-500 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  @screen lg {
    font-size: 16px;
    line-height: 24px;
  }
}

.text-12-16-500_14-24-500 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;

  @screen lg {
    font-size: 14px;
    line-height: 24px;
  }
}

.text-11-15-600_12-24-600 {
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;

  @screen lg {
    font-size: 12px;
    line-height: 24px;
  }
}

.text-24-40-500_32-40-500 {
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;

  @screen lg {
    font-size: 32px;
    line-height: 40px;
  }
}

.text-32-40-600_54-64-600 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;

  @screen lg {
    font-size: 54px;
    line-height: 64px;
  }
}

.text-30-32-600_50-52-600 {
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;

  @screen lg {
    font-size: 50px;
    line-height: 52px;
  }
}

.text-30-32-700_50-52-700 {
  font-size: 30px;
  line-height: 32px;
  font-weight: 700;

  @screen lg {
    font-size: 50px;
    line-height: 52px;
  }
}

.text-16-18-400_48-50-400 {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;

  @screen lg {
    font-size: 48px;
    line-height: 50px;
  }
}

.text-arrow-div {
  @apply text-purple div-light-purple py-[24px] px-[16px] lg:px-[24px] rounded-[16px] flex justify-between items-center;
  svg {
    color: $color-purple;
  }

  &:hover {
    @apply text-white div-purple;
    box-shadow: 0px 20px 80px #a5afd3;

    svg {
      color: #fff;
    }
  }
}

.text-arrow-div-chosen {
  @apply text-white div-purple;
  
  svg {
    color: #fff;
  }
}

.defaultInput {
  .MuiInputBase-root {
    border-radius: 8px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid $color-faded-purple;
  }

  .MuiInputLabel-root.Mui-focused {
    color: $color-grey !important;
  }

  .MuiInputLabel-outlined {
    @extend .text-14-24-500;

    color: $color-grey;
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $color-faded-purple !important;
    }
  }
}

.defaultRadio {
  .MuiSvgIcon-root {
    fill: $color-purple;
    width: 20px;
    height: 20px;
  }

  .MuiSvgIcon-root[data-testid="RadioButtonCheckedIcon"] {
    fill: white;
  }

  .MuiSvgIcon-root[data-testid="RadioButtonUncheckedIcon"] {
    fill: transparent;
  }

  &.Mui-checked {
    .MuiSvgIcon-root[data-testid="RadioButtonCheckedIcon"] {
      // transform: scale(1.35);
    }

    span:first-of-type {
      background-color: $color-purple;
    }
  }

  span:not(.MuiTouchRipple-root) {
    background-color: $color-light-purple;
    border-radius: 999px;
  }
}

.homeHeroCardHolder {
  height: 329px;
  width: 343px;

  @screen lg {
    height: 496px;
    max-width: 543px;
    min-width: 500px;
    width: 45vw;
    @apply mt-0;
  }

  @apply relative mt-[24px];
  foo: bar;
}

.verticalImageCard {
  border-radius: 16px;
  box-shadow: 0px 20px 80px rgba(17, 25, 80, 0.13);

  .image {
    height: 147px;
    @screen lg {
      width: 270px;
      height: 200px;
    }

    border-radius: 12px;
    object-fit: cover;
  }

  .star {
    width: 13px;
    height: 13px;

    @screen lg {
      height: 15px;
      width: 15px;
    }
  }

  .header {
    @extend .text-14-18-600_18-24-600;
    color: $color-black;
    @apply pt-[12px] lg:pt-[16px];
    foo: bar;
  }

  .sub1 {
    @extend .text-12-16-500_16-24-500;
    color: $color-light-grey;
    @apply pt-[8px];
    foo: bar;
  }

  .sub2 {
    @extend .text-12-16-500_14-24-500;
    color: $color-light-grey;
    @apply pt-[4px];
    foo: bar;
  }

  .score {
    @extend .text-11-15-600_12-24-600;
    @extend .text-grey;
  }

  @extend .flex-col;
  @apply bg-white p-[12px] lg:p-[16px];
  foo: bar;
}

.checkBox {
  border: 1px solid #b8b9f7;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  color: white;

  &[data-checked] {
    background-color: $color-purple;
  }

  .css-0 {
    width: 75%;
  }
}

.main-padding {
  @apply px-[16px] lg:px-[80px];
  foo: bar;
}

.main-width {
  @apply max-w-[343px] lg:max-w-[1440px] w-full self-center;
  foo: bar;
}

.main-width-desktop {
  @apply lg:max-w-[1440px] w-full self-center;
  foo: bar;
}

.main-width1200 {
  @apply max-w-[343px] lg:max-w-[1200px] w-full self-center;
  foo: bar;
}

.main-width1280 {
  @apply max-w-[343px] lg:max-w-[1280px] w-full self-center;
  foo: bar;
}

.menu-box {
  box-shadow: 0px 10px 60px rgba(40, 41, 114, 0.07);
  border-radius: 12px;

  --popper-arrow-size: 24px;
  --popper-arrow-bg: white;

  .chakra-popover__arrow-positioner {
    top: -9px !important;
    z-index: 10 !important;
  }

  .chakra-popover__header {
    div {
      @extend .text-14-24-600;
      @apply flex justify-between;
      foo: bar;
    }
  }

  @apply bg-white p-[24px];
  foo: bar;
}

.menu-item {
  &-hover:hover {
    border-radius: 12px;
    color: $color-purple;
    background-color: $color-lightest-purple;

    img {
      display: block;
    }
  }

  @extend .text-14-24-500;
  color: $color-grey;
  padding: 24px;
  width: 280px;

  img {
    display: none;
  }

  @apply flex justify-between;
  foo: bar;
}

.full-menu-item {
  @extend .menu-item;
  width: unset !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.topbar_menu {
  @apply flex gap-[32px];
  foo: bar;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modalHeader {
  @apply pb-[12px] mb-[20px] flex justify-between;
  border-bottom: 1px solid #d6d6d6;

  h1 {
    color: var(--color-palette-text-1, #232226);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
  }
}

.swiper-full-height {
  .swiper-slide {
    height: unset !important;
  }
}

.swiper {
  // width: calc(100% - 120px) !important;
  width: 100%;
  height: 100%;
  display: flex;
  transition-property: padding-left;
  transition-duration: 0.2s;
  transition-delay: 0.2s;
  @media (min-width: $breakpoint-desktop) {
    padding-bottom: 32px !important;
  }
  @media (max-width: $breakpoint-desktop) {
    padding-bottom: 24px !important;
  }

  .swiper-slide-active {
    // @media (max-width: $breakpoint-desktop) {
    //   padding-left: calc(50vw - 156px);
    // }
  }

  .swiper-slide {
    @media (max-width: $breakpoint-desktop) {
      // width: 312px !important;
      width: 83vw;
    }
  }

  &.notActiveEffect {
    .contentHolder .content {
      place-content: center;
      background: none;
      box-shadow: none;
    }

    .swiper-slide.swiper-slide-active .contentHolder .content {
      background: none;
      box-shadow: none;
    }
  }
}

.swiperMini {
  @extend .swiper;
  padding-bottom: 0px !important;
}

.swiper-flex-height {
  .swiper-slide {
    height: unset !important;
  }
}

.swiper-pictures {
  .swiper-slide {
    width: 212px !important;
  }

  .content {
    width: 192px !important;
  }
}

.swiper_arrow {
  @screen xl {
    &:hover {
      filter: invert(37%) sepia(97%) saturate(3689%) hue-rotate(215deg) brightness(99%) contrast(104%);
    }
  }
}

.divider {
  flex-grow: 0;
  width: 1px;
  height: 32px;
  background-color: $color-light-purple;
  margin: 12px 5px;
}

.dividerH {
  flex-grow: 0;
  height: 1px;
  width: 32px;
  background-color: $color-light-purple;
  margin: 12px 5px;
}

.full-divider {
  @extend .divider;
  margin: 0px;
  height: 100%;
}

.full-dividerH {
  @extend .dividerH;
  margin: 0px;
  width: 100%;
}

.filtersParent {
  @apply py-[24px] lg:py-[48px] flex justify-center;
  foo: bar;

  .filters {
    @media (max-width: $breakpoint-desktop) {
      flex-direction: column;
      filter: drop-shadow(0px 20px 80px #ebecf0);
    }
    @media (min-width: $breakpoint-desktop) {
      background-color: #fff;
      box-shadow: 0px 12px 120px #e3e6f0;
    }

    border-radius: 16px;

    .item {
      flex-grow: 1;

      .image {
        @apply h-[24px] w-[24px];
        foo: bar;
      }

      .text {
        @extend .text-14-24-500;
        @apply pl-[12px] pr-[24px];
        foo: bar;
      }

      .arrow {
        transition: transform 0.2s ease-in-out;
      }

      @apply flex justify-between lg:items-center lg:justify-center;
      foo: bar;
    }

    .menu-title {
      @media (max-width: $breakpoint-desktop) {
        width: 100%;
      }

      &[aria-expanded="true"] {
        .arrow {
          transform: rotate(180deg);
        }
      }
    }

    @apply w-full flex justify-evenly max-w-[881px];
    foo: bar;
  }
}

.chakra-popover__popper {
  z-index: 10 !important;
}

.searchprice {
  @extend .text-black;
  @extend .text-18-24-600;
  foo: bar;
}

.swiper-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;

  padding-top: 32px;
  padding-bottom: 32px;

  .contentHolder {
    text-align: center;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    // width: 320px !important;
    width: 80vw;

    .header {
      color: $color-black;
      // if you want to center header vertically
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
    }

    .sub {
      color: $color-grey;
    }

    .content {
      @extend .card-rounded;

      background-color: #fff;
      box-shadow: 0px 0px 20px 0px #ebecf0;
      @apply px-[16px] py-[16px] lg:px-[20px] lg:py-[24px] w-full;
      foo: bar;
    }

    &.noShadow {
      box-shadow: none !important;

      .content {
        box-shadow: none !important;
        filter: none !important;
      }
    }

    @apply flex-col px-[10px] h-[152px] lg:h-[128px];
    foo: bar;
  }

  &.swiper-slide-active {
    .contentHolder {
      .header {
        @media (min-width: $breakpoint-desktop) {
          color: white;
        }
        color: $color-black;
      }

      .sub {
        @media (min-width: $breakpoint-desktop) {
          color: $color-light-purple;
        }
        color: $color-light-grey;
      }

      .content {
        @media (min-width: $breakpoint-desktop) {
          background-color: $color-purple;
          box-shadow: 0px 20px 30px #a5afd3;
        }
        filter: drop-shadow(0px 20px 20px #ebecf0);
      }
    }
  }

  @apply lg:px-[12px];
  foo: bar;
}

.purpleButton {
  &.MuiButtonBase-root {
    @extend .div-purple;
    @extend .text-white;

    &:hover {
      background-color: $color-dark-purple;
    }

    span {
      @extend .text-white;
      @extend .text-16-16-600;
    }

    &.Mui-disabled {
      @extend .div-disabled-grey;
    }

    text-transform: none;

    @apply rounded-[8px] px-[48px] py-[16px] text-center;
    foo: bar;
  }
}

.purpleButtonMini {
  @extend .purpleButton;
  padding: 12px 24px !important;
}



.purpleButtonMiniMobile {
  @media (max-width: $breakpoint-desktop) {
    &.MuiButtonBase-root {
      padding: 10px 20px !important;
      span {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
      }
    }
  }
}

.purpleButtonWide {
  @extend .purpleButton;
  font-size: 16px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
}

.greyButtonMini {
  @extend .purpleButtonMini;
  background-color: $color-light-grey !important;
}

.contactus {
  border: 2px solid #cffc83;

  @screen lg {
    border: none;
  }

  @apply flex-col justify-between lg:mt-[136px] px-[16px] lg:px-[120px] items-center h-full overflow-hidden main-dropshadow rounded-[16px] relative;
  foo: bar;
}

.kep-login-facebook {
  display: none !important;
}

@font-face {
  font-family: "Surt";
  src: url("https://pofft.com/fonts/Surt-RegularExtended.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Surt";
  src: url("https://pofft.com/fonts/Surt-MediumExtended.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Surt";
  src: url("https://pofft.com/fonts/Surt-SemiBoldExtended.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Surt";
  src: url("https://pofft.com/fonts/Surt-BoldExtended.otf") format("opentype");
  font-weight: 700;
}

body {
  font-family: 'Surt' !important;
}

.MuiChip-filled {
  background-color: $color-purple !important;

  .MuiChip-label {
    color: white;
    font-size: 14px;
  }

  .MuiChip-deleteIcon {
    color: rgba(255, 255, 255, 0.85) !important;
  }
}

.numberInput {
  height: 44px;
  width: 60px;
  border: 1px solid #5658FF;
  border-radius: 10px;
  background: #FFF;
  text-align: center;
  color: #6D6D6D;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.priceInput {
  letter-spacing: unset !important;
  width: 100%;
  height: 44px;
  border-width: 1px;
  border-color: #5658FF !important;
  border-radius: 10px !important;
  background: #FFF;
  text-align: center;
  color: #6D6D6D;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  .MuiInputBase-input {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }

  outline: none !important;

  &:focus {
    border-width: 1px !important;
  }

  input {
    text-align: center;
    color: #6D6D6D;
    font-size: 14px;
    font-weight: 500;
    min-height: 21px;
    //line-height: 24px;
  }

  .MuiOutlinedInput-root.Mui-focused {
    border: 1px solid #5658FF !important;

    fieldset {
      border-color: transparent !important;
      //margin-bottom: 8px !important;
      border-width: 1px !important;
    }
  }
}

.successTexts {
  @apply flex flex-col gap-[8px];

  color: var(--main-brand-colors-quaternary, #05D490);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &.bigText {
    font-size: 24px;
    line-height: 40px;
    gap: 0px;
  }

}

.y-scroll {
  overflow-y: auto;
  /*-ms-overflow-style: none;*/
  /* IE and Edge */
  /*scrollbar-width: none;*/
  /* Firefox */
}
/*.y-scroll::-webkit-scrollbar {*/
/*!*  display: none;*!*/
/*}*/

.y-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.y-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.purple-y-scroll {
  overflow-y: scroll;
  scrollbar-color: $color-scroll-purple transparent;

  -ms-overflow-style: auto;
  /* IE and Edge */
  scrollbar-width: thin;
  /* Firefox */
}

.messageBox {
  @apply flex gap-[17px] items-center;
  foo: bar;

  .message {
    @apply px-[24px] py-[10px] flex justify-center max-w-[450px];
    background-color: $color-grey4;
  }

  .text {
    color: $color-grey;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
  }

  .date {
    color: $color-light-grey;
    font-size: 10px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }

}

.sentMessage {
  border-radius: 8px 8px 0px 8px;
}

.receivedMessage {
  border-radius: 8px 8px 8px 0px;
}

.standardInput {
  @apply pl-[20px] rounded-[16px] w-full h-[44px];

  resize: none;
  border-width: 1px;
  border-color: #5658FF !important;
  border-radius: 10px;
  background: #FFF;
  text-align: start;
  color: #6D6D6D;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

  &.compact {
    height: unset !important;
    padding-left: 0px !important;
  }

  &.colorless {
    border: none !important;
    background-color: unset !important;
  }

  &.white-color {
    color: white !important;
  }

  .MuiInputBase-input {
    padding-top: 11px !important;
    padding-bottom: 11px !important;
  }

  &:focus {
    outline: none !important;
    border-width: 1.5px !important;
  }

  input {
    text-align: center;
    color: #6D6D6D;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .MuiOutlinedInput-root.Mui-focused {
    border: 1px solid #5658FF !important;

    fieldset {
      border-color: #5658FF !important;
      border-radius: 9999px !important;
      margin-bottom: 8px !important;
      border-width: 1px !important;
    }
  }
}

.standardBigInput {
  @extend .standardInput;
  font-size: 16px !important;
  border-radius: 16px !important;
  height: 76px;
  color: #232226 !important;

  ::placeholder {
    color: #6D6D6D;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder { /* Edge 12 -18 */
    color: #6D6D6D;
  }

}

textarea.standardBigInput {
  @apply py-[30px] px-[20px];
  foo: bar;
}

.priceTitle {
  color: #6D6D6D;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.MuiBox-root {
  outline: none !important;
}

.MuiBox-root:has(.filterModal) {

  @screen maxtablet {
    min-width: 300px;
    width: calc(max(90vw, 300px));
  }
}

.dividerVertical {
  background-color: #D6D6D6;
  width: 1px;
  height: 100%;
}

.dividerHorizontal {
  background-color: #D6D6D6;
  width: 100%;
  height: 1px;
}

.similar-jobs {
  @apply flex flex-col gap-[16px];
  foo: bar;

  .similar-jobs-title {
    color: var(--content-text-primary, #232226);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
  }

  .similar-jobs-description {
    color: var(--content-text-secondary, #6D6D6D);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.message-overview {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 202px;
  color: #6D6D6D;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
}

.message-date-overview {
  color: #6D6D6D;
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
}

.fullSlider {
  swiper-container {
    width: 100%;
  }
}

.certificateSwiper {
  swiper-container {
    width: 100%;
  }
}

.searchResult {

  background-color: white;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 20px 80px 0px #EBECF0;

  &:hover {
    background-color: #F6F6FF;
  }

  .title {
    color: #232226;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .location {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .description {
    color: #999999;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .darkerdescription {
    color: #6D6D6D;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  .thindescription {
    color: #999999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .more {
    color: #5658FF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .like {
    width: 39px;
    height: 39px;
    border-radius: 99px;
    border: 1px solid #5658FF;
    background: #EDEDFF;
    padding: 10px 10px 9px 9px;
    justify-content: center;
    align-items: center;
  }

  .tagsSwiper {

    margin-top: auto;
    width: calc(100% + 16px);
    left: -8px;
    position: relative;

    swiper-container {
      height: 40px;
      width: 100%;
    }

    swiper-slide {
      width: auto;
      text-align: center;
      font-size: 18px;
      background: #fff;
      //display: flex;
      //justify-content: center;
      //align-items: center;
    }

    .slide {
      border-radius: 24px;
      background: #F6F6FF;
      padding: 8px 32px;
      //display: flex;
      //justify-content: center;
      //align-items: center;

      color: #6D6D6D;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

  }

}

.searchResultContainer {
  @apply flex-col bg-white rounded-[16px] px-[24px] py-[16px];
  box-shadow: 0px 10px 30px 0px #EBECF0;
}

.whiteShadowCard {
  @apply bg-white;
  border-radius: 16px;
  box-shadow: 0px 20px 80px 0px #EBECF0;
}

.lightestPurpleCard {
  background-color: $color-lightest-purple;
  border-radius: 16px;
  box-shadow: 0px 20px 80px 0px #EBECF0;
}

.purpleCard {
  background-color: $color-purple;
  border-radius: 16px;
}

.purpleDot {
  @apply w-[2px] h-[2px] rounded-[10px];
  background-color: $color-purple;
}

.modalTitle {
  color: #232226;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

}

.socialmediaContainer {
  border-radius: 40px;
  border: 1px solid $color-faded-purple;
  display: flex;
  padding: 17px 0px;
  justify-content: center;
  align-items: center;

  div {
    @apply flex gap-[12px];
    foo: bar;
  }

  img {
    @apply w-[24px] h-[24px];
    foo: bar;
  }

  &.filled {
    background-color: $color-purple;
    border-color: $color-purple;
    color: white;
  }
}

.modalSub {
  color: #232226;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.dateInput {
  fieldset {
    border-radius: 10px !important;
    border-color: #5658FF !important;
  }

  input {
    @apply py-[12px];
    color: #232226;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

}

.dropzone {
  border-radius: 16px;
  border: 1px dashed var(--main-brand-colors-primary, #5658FF);
  padding: 39px 35.5px !important;
  color: var(--color-palette-text-1, #232226);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  strong {
    color: var(--stroke-input-focus, #5658FF);
  }
}

.certificateSwiper {
  swiper-slide {
    width: 227px;
    height: 100%;
  }
}

.experiencesSwiper {
  swiper-container {
    width: 100%;
  }

  swiper-slide {
    min-width: 440px;
  }
}

.MuiPickersCalendarHeader-label {
  color: #5658FF;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 123.077% */
}

.MuiPickersDay-root.Mui-selected {
  background-color: #F6F6FF !important;
  color: #5658FF !important;
}

.MuiIconButton-root {
  right: 4px;
  padding: 5px !important;

  &:hover {
    background-color: transparent !important;
  }

  &:focus {
    background-color: transparent !important;
  }
}

.topbarSwitcher {
  display: flex;
  padding: 4px;
  border-radius: 100px;
  border: 1px solid var(--sementics-color-border-muted, #E4E4E7);
  background: #FFF;

  .item {
    @extend .text-14-16-500;
    @apply px-[16px] py-[8px];
    border-radius: 100px;
    color: #8A8A92;
    background-color: transparent;

    &.selected {
      color: #FFF;
      background-color: #5658FF;
    }
  }
}

.preventSelect {
  user-select: none;
}

div .loadingButton {
  @apply relative;
  background-color: #7a7a7a !important;

  div {
    @apply absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
}

.absolutetop0 {
  position: absolute;
  top: 0;
}

.MuiTelInput-TextField {

  img {
    border-radius: 4px;
  }

  input {
    height: 12px !important;
    font-size: 14px !important;
    font-family: 'Surt' !important;
  }

  .MuiTypography-root {
    font-size: 14px !important;
    font-family: 'Surt' !important;
  }

  fieldset {
    border: 1px solid #5658FF !important;
    border-radius: 10px;
  }

  .Mui-focused {
    fieldset {
      border: 1.5px solid #5658FF !important;
      border-radius: 10px;
    }

    border: none !important;
    border-radius: 10px;
  }
}

.clampedInnerHTML {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.zFrontUlChild {
  ul {
    z-index: 10;
  }
}

.purple-border-input-round16 {

  fieldset {
    border-radius: 16px !important;
    border: 1px solid $color-purple !important;

  }
}

.paymentBox {
  @extend .div-lightest-purple;


  &:hover {
    box-shadow: 0px 20px 80px 0px #EBECF0;
    background-color: #fff;
  }

}

.documentContainer {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
  padding: 20px;
  color: #333;
}
.documentHeader {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #2c3e50;
}
.documentTitle {
  font-size: 18px;
  margin-top: 20px;
  color: #2c3e50;
}
.documentText {
  margin: 10px 0;
  text-align: justify;
}
.documentTextBig {
  font-size: 24px;
}
.documentList {
  margin: 10px 0 10px 20px;
  list-style-type: disc;
}
.documentGrid {
  display: grid;
  grid-template-columns: 2fr 8fr;
  gap: 10px;
}
.documentSubSection {
  margin-top: 15px;
  font-weight: bold;
  text-decoration: underline;
}
.documentVGap{
  height: 16px;
}

.documentTable {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;

  table, th, td {
    border: 1px solid #ddd;
  }
  th, td {
    padding: 10px;
    text-align: left;
  }
  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  caption {
    font-weight: bold;
    margin-bottom: 10px;
    text-align: start;
  }
}

* {
  font-family: 'Surt' !important;
  --swiper-pagination-bullet-size: 0px;
}
